
import Vue from 'vue';
import statusCheck from '../../mixins/statusCheck';
import { C360Provider, defineAbilityFor } from '@c360/ui';
import utils from '../../util';

export default Vue.extend({
  components: {
    ProjectVersion: () => import('../global/projectVersion.vue'),
    AdminToolbar: () => import('../global/adminToolbar.vue'),
    AdvertiserList: () => import('./advertiserList.vue'),
    AdvertiserDetails: () => import('./details.vue'),
    FooterC360: () => import('../global/footerC360.vue'),
    Status: () => import('../global/status.vue'),
    AppGlobals: () => import('../global/globals.vue'),
    C360Provider,
  },
  data(): {
    currentView: string;
    ppid: string | null;
    action: string | null;
    lastCurrentPage: number | null;
    refreshToken: string;
    config: object;
  } {
    return {
      currentView: 'list',
      ppid: null,
      action: null,
      lastCurrentPage: null,
      refreshToken: '',
      config: {
        whatIsNewDialog: {
          page: '',
        },
      },
    };
  },
  mixins: [statusCheck],
  mounted() {
    this.refreshToken = this.$store.state?.customer?.user?.refreshToken;
    setTimeout(() => {
      this.config = {
        whatIsNewDialog: {
          page: `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`,
        },
      };
    }, 500);
  },
  created() {
    if (this.$route.query.ppid) {
      this.goToAdvertiser(this.$route.query.ppid);
    }
  },
  watch: {
    '$route.query.ppid': {
      handler(ppid: boolean): void {
        if (ppid) {
          this.goToAdvertiser(ppid);
        } else {
          this.goToList();
        }
      },
    },
    '$store.state.customer.user.accessToken': {
      handler(newState: boolean): void {
        this.refreshToken = newState;
      },
    },
  },
  methods: {
    goToAdvertiser(propertyId: string): void {
      this.currentView = 'details';
      this.ppid = propertyId;
    },
    goToList(): void {
      this.currentView = 'list';
      this.ppid = null;
      this.action = null;
      this.$router.push({ query: {} });
    },
    openAction(action: string): void {
      this.action = action;
    },
    updatePage(pg: number) {
      this.lastCurrentPage = pg;
    },
  },
  computed: {
    accessToken() {
      return localStorage.getItem('ah:accessToken');
    },
    refreshTokenLS() {
      return localStorage.getItem('ah:refreshToken');
    },
    ability() {
      return defineAbilityFor({
        isSuperUser: this.$store.state?.customer?.user?.is_superuser ?? false,
        isAgencyAdmin: utils.hasRight(this.$store.state?.customer?.user, ['AGENCY_ADMIN']) ?? false,
        products: this.$store.state?.customer?.user?.products ?? [],
        permissions: this.$store.state?.customer?.user?.permissions ?? [],
        activeAgencyName: this.$store.state?.customer?.user?.active_agency_id ?? '',
        tenantsCount: this.$store.state?.customer?.user?.AvailableAgencies?.length ?? 1,
      });
    },
    c360Path(): string {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
  },
});
